<template>
  <div class="w-1/2 p-2">
    <div @click="$router.push('/shopping')" class="relative flex flex-col items-center px-4 py-4 bg-white rounded-md shadow cursor-pointer dark:bg-gray-900 hover:shadow-lg">
      <img src="../../assets/shoppinglist.jpg" class="absolute inset-0 w-full h-full rounded-md opacity-25">
      <svg class="w-12 h-12 opacity-50 dark:opacity-75" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"></path></svg>
      <span class="mt-4 opacity-75">Einkaufsliste</span>
      <span class="absolute px-2 text-xs font-semibold text-white rounded-full shadow py-2px -top-1 -right-1 bg-primary-600">{{$store.getters['shoppinglist/itemsInList'](1)}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    compact: {
      type: Boolean,
      default: false
    }
  },
}
</script>
