export default {
  methods: {
    formatCurrency(amt) {
      let formatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'EUR'
      })
      return formatter.format(amt)
    }
  }
}