<template>
  <div class="container flex flex-wrap max-w-md px-2 pt-2 mx-auto">
    <shoppinglist />
    <recipes />
    <storage />
    <bills />
  </div>
</template>

<script>
import recipes from '@/components/apps/recipes'
import shoppinglist from '@/components/apps/shoppinglist'
import storage from '@/components/apps/storage'
import bills from '@/components/apps/bills'

export default {
  components: { recipes, shoppinglist, storage, bills }
}
</script>
