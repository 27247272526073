<template>
  <div class="w-1/2 p-2">
    <div @click="$router.push('/storage')" class="relative flex flex-col items-center px-4 py-4 bg-white rounded-md shadow cursor-pointer dark:bg-gray-900 hover:shadow-lg">
      <img src="../../assets/storage.jpg" class="absolute inset-0 w-full h-full rounded-md opacity-25">
      <svg class="w-12 h-12 opacity-50 dark:opacity-75" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"></path></svg>
      <span class="mt-4 opacity-75">Vorräte</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    compact: {
      type: Boolean,
      default: false
    }
  },
}
</script>
