<template>
  <div class="w-1/2 p-2">
    <div @click="$router.push('/bills')" class="relative flex flex-col items-center px-4 py-4 bg-white rounded-md shadow cursor-pointer dark:bg-gray-900 hover:shadow-lg">
      <img src="../../assets/bills.jpg" class="absolute inset-0 w-full h-full rounded-md opacity-25">
      <svg class="w-12 h-12 opacity-50 dark:opacity-75" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
      <span class="mt-4 opacity-75">Haushaltsbuch</span>
      <span class="absolute px-2 text-xs font-semibold text-white rounded-full shadow py-2px -top-1 -right-1 bg-primary-600">{{formatCurrency($store.getters['bills/total'])}}</span>
    </div>
  </div>
</template>

<script>
import currency from '@/mixins/currency'

export default {
  props: {
    compact: {
      type: Boolean,
      default: false
    }
  },
  mixins: [ currency ]
}
</script>
