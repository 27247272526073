<template>
  <div class="w-1/2 p-2">
    <div @click="$router.push('/recipes')" class="relative flex flex-col items-center px-4 py-4 bg-white rounded-md shadow cursor-pointer dark:bg-gray-900 hover:shadow-lg">
      <img v-if="latestRecipe && latestRecipe.imageUrl" :src="latestRecipe.imageUrl" class="absolute inset-0 w-full h-full rounded-md opacity-25">
      <svg class="w-12 h-12 opacity-50 dark:opacity-75" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path></svg>
      <span class="mt-4 opacity-75">Rezeptbuch</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    compact: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    latestRecipe: vm => vm.$store.getters['recipes/latest']
  }
}
</script>
